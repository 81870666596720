import React from 'react'

// Data
import projectsData from '../../../data/projects.json'

// Components
import ProjectCard from './ProjectCard'

// Styles
import './projects.css'

export default () => <section className='projects' id='projects'>

    <h1 className='projects-header'>Projects</h1>

    <ul className='projects-list'>
        { projectsData.map(elm => <ProjectCard key={ elm.order } { ...elm } />) }
    </ul>

</section>