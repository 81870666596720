import React from 'react'

// Soft-skill data
import softSkills from '../../../../../data/softSkills.json'

// Components
import SoftSkillElm from './SoftSkillElm'

// Styles
import './soft-skills.css'

export default () => <div className='soft-skills'>

    <h3 className='header'>Soft-skills</h3>

    <ul>
        { softSkills.map(elm => <SoftSkillElm key={ elm.skill } { ...elm } />) }
    </ul>

</div>