import React from 'react'

// Components
import Head from './Head'
import Body from './Body'

// Styles
import './speech.css'

export default () => <article className='speech'>

    <Head />
    <Body />

</article>