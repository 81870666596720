import React from 'react'

// Components
import TechGroup from './TechGroup'

// Styles
import './technologies.css'

// Data
import technologies from '../../../../data/technologies.json'

export default () => <section className='technologies'>
    <h3 className='tech-title'>Technologies</h3>

    <div className='container'>
        <div className='tech-info'>

            { Object.keys(technologies).map(elm => <TechGroup key={ elm } name={ elm } group={ technologies[elm] } />) }

        </div>
    </div>
</section>