import React from 'react'

import './speech-body.css'

export default () => <div className='speech-body'>

    <p className='text'>I studied in Madrid the Full-Stack Development bootcamp in Ironhack, where I learned to use some different technologies for the web development, as: React, Node, Express, HTML, CSS, JS(ES6), MongoDB... Previously, I studied in Malaga University Electronics, Robotics and Mecatronics Engineering, pending of finalization and parallel I made son other courses: Java oriented to Android, electric vehicles...</p>

    <p className='text'>Resolutive, curious, hard-working, friendly. Looking for have deeper and varied knowledge and new challenges. Actually I am studying new technologies and developing new projects with the purpose of apply the new knowledges acquired previously and expand new hard skills, try new designs, methodologies...</p>

    <p className='text'>I have to say I strive a lot to provide solutions as professionals as I can, emphasizing clean and quality of code, using as far as posible the tools we have, as: methods, functions, dependencies... </p>

</div>