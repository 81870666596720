import React from 'react'

// Components
import Speech from './Speech'
import SoftSkill from './SoftSkills'

// Styles
import './about.css'

export default props => <section className='description'>

    <Speech />
    <SoftSkill />

</section>