//React imports
import React, { useEffect } from 'react'

// Components

// UI
import Navbar from './components/ui/Navbar'
import Footer from './components/ui/Footer'

// Sections
import Hero from './components/sections/Hero'
import About from './components/sections/About'
import Projects from './components/sections/Projects'


// Styles
import './App.css'


export default function App() {


  // USEEFFECT
  useEffect(() => {
    const ids = [document.getElementById('hero'), document.getElementById('about'), document.getElementById('projects')]

    window.addEventListener("scroll", () => {

      for (let i = ids.length - 1; i >= 0; i--) {

        if (ids[i].offsetTop <= window.pageYOffset) {
          if (window.location.hash !== `#${ids[i].id}`) return window.history.replaceState(null, null, '#' + ids[i].id)
          break
        }

      }
    }, { passive: true })
  }, [])

  useEffect(() => {

    if (/\/[cv|cv.pdf]/i.test(window.location.pathname))
      window.location.replace('/NelsonCabreraCanoCV.pdf')

    if (/\/[photo]/.test(window.location.pathname))
      window.location.replace('/images/about/photo.jpg')

    if (/\/[github]/.test(window.location.pathname))
      window.location.replace('https://github.com/NelsonCC1812')

    if (/\/[linkedin]/.test(window.location.pathname))
      window.location.replace('https://linkedin.com/in/nelson-cc')

  })

  //RETURN
  return <main>
    <Navbar />

    <Hero />
    <About />
    <Projects />

    <Footer />
  </main>
}
