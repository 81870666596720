import React from 'react'

// Components
import Descripton from './Description'
import Technologies from './Technologies'

export default () => <section className='about' id='about'>

    <Descripton />
    <Technologies />

</section>