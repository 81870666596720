import React, { useState, useEffect } from 'react'

// Image
import menuImage from './menu.svg'

// Components
import ModalForm from '../../layouts/Modal'
import ContactForm from '../ContactForm'

// Styles
import './navbar.css'

export default props => {

    const [showModalForm, setShowModalForm] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    // Handles if the navbar is hidden (scroll)
    useEffect(() => {
        let prevY = window.pageYOffset

        window.addEventListener('scroll', () => {

            if (prevY < window.pageYOffset + .1) {
                document.getElementsByClassName('navbar')[0].classList.add('uphidden')
            } else if (prevY > window.pageYOffset - .1) {
                document.getElementsByClassName('navbar')[0].classList.remove('uphidden')
            }
            prevY = window.pageYOffset
        }, { passive: true })
    })

    useEffect(() => {
        showMenu && document.querySelector('body').classList.add('blocked-scroll')

        !showMenu && document.querySelector('body').classList.remove('blocked-scroll')
    }, [showMenu])

    return <div className='navbar'>
        <div className='navbar-container'>

            <div className='nav-header'>
                <a href='#hero' className='home-link'>Home</a>
                <figure><img className={ `menu-img ${showMenu ? 'opened-menu' : ''}` } src={ menuImage } alt='menu' onClick={ () => setShowMenu(!showMenu) } /></figure>
            </div>

            <nav className={ `nav-links ${showMenu ? 'show-menu' : ''}` }>
                <ul>
                    <li><a href='#about' className='link' onClick={ () => setShowMenu(false) }>About me</a></li>
                    <li><a href='#projects' className='link' onClick={ () => setShowMenu(false) }>My projects</a></li>
                    <li><button className='link btn-link' onClick={ () => { setShowModalForm(true); setShowMenu(false) } }>Contact me</button></li>
                </ul>
            </nav>

        </div>

        <ModalForm show={ showModalForm } Component={ ContactForm } closeModal={ () => setShowModalForm(false) } />
    </div>
}