import React, { useEffect, useRef } from 'react'

// Styles
import './projectCard.css'

export default ({ order, name, description, objective, duration, image, link }) => {

    const cardRef = useRef()
    const infoRef = useRef()
    const titleRef = useRef()

    useEffect(() => {
        cardRef.current.addEventListener('mouseover', e => {
            infoRef.current.classList.remove('collapsed')
            titleRef.current.classList.add('overlap')
        })
        cardRef.current.addEventListener('mouseout', e => {
            infoRef.current.classList.add('collapsed')
            titleRef.current.classList.remove('overlap')
        })

        cardRef.current.addEventListener('touchstart', e => {
            infoRef.current.classList.remove('collapsed')
            titleRef.current.classList.add('overlap')
        })
        cardRef.current.addEventListener('touchend', e => {
            infoRef.current.classList.add('collapsed')
            titleRef.current.classList.remove('overlap')
        })
    }, [])

    return <article className='project-card' ref={ cardRef } >
        <a rel='noopener noreferrer' target='_blank' href={ link }>

            <div className='card-header'>
                <figure><img src={ image } alt={ name } /></figure>
                <h5 className='card-title' ref={ titleRef }>{ name }</h5>
            </div>

            <div className='card-info collapsed' ref={ infoRef } >
                <p><strong>Description: </strong>{ description }</p>
                <p><strong>Objective: </strong>{ objective }</p>
                <p><strong>Duration: </strong>{ duration + ` week${duration > 1 ? 's' : ''}` }</p>
            </div>

        </a>
    </article>
}