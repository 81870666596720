import React from 'react'

// Styles
import './speech-head.css'

export default () => {

    const photoEffect = () => {
        document.querySelector('.photo').classList.add('rotating')
        setTimeout(() => document.querySelector('.photo').classList.remove('rotating'), 3000)
    }

    return <div className='speech-head'>
        <figure><img className='photo' src='./images/about/photo.jpg' alt='Nelson Cabrera Cano' onClick={ photoEffect } /></figure>

        <ul className='links' >

            <a className='link' rel='noreferrer noopener' target='_blank' href='https://linkedin.com/in/nelson-cc'>
                <img src='./images/about/linkedin.svg' alt='My linkedin' />
                <p>Linkedin</p>
            </a>

            <a className='link' rel='noreferrer noopener' target='_blank' href='https://github.com/nelsoncc1812'>
                <img src='./images/about/github.svg' alt='My github' />
                <p>Github</p>
            </a>

            <a className='link' href='NelsonCabreraCanoCV.pdf' title='My Curriculum'>
                <img src='./images/about/cv.svg' alt='My CV' />
                <p>My CV</p>
            </a>

        </ul>

    </div>
}