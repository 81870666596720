import { AnonymousCredential, RemoteMongoClient, Stitch } from 'mongodb-stitch-browser-sdk'

const stitchAppClient = Stitch.initializeDefaultAppClient('portfolio-iifky')

stitchAppClient.auth
    .loginWithCredential(new AnonymousCredential())
    .catch(err => console.log('Atlas auth error'))

const mongoClient = Stitch.defaultAppClient.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas')
const Mails = mongoClient.db('mails').collection('mails')


export const sendMail = async mail => await Mails.insertOne(mail)