import React from 'react'

// Components
import TechCard from './TechCard'

// Styles
import './techGroup.css'

export default ({ name, group }) => <article className='tech-group'>
    <h5 className='group-title'>{ name }</h5>

    <div className='cards-group'>
        { group.map(elm => <TechCard key={ elm.name } { ...elm } />) }
    </div>

</article>