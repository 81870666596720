import React from 'react'

// Styles
import './techCard.css'

export default ({ name, img, link }) => <a href={ link } target='_blank' rel='noreferrer noopener' className='tech-card'>

    <figure><img src={ img } alt={ name } /></figure>
    <h6>{ name }</h6>

</a>