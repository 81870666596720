import React, { useEffect } from 'react'

import './hero.css'

export default props => {

    const typeWriter = (elm, data, speed, index = 0, width = 0) => {

        if (index === 0) {
            width = parseInt(getComputedStyle(elm).fontSize.replace(/[a-zA-z]/g, ""))
        }

        if (index < data.length) {
            elm.style.maxWidth = index * width * .57 + 'px'
            setTimeout(() => typeWriter(elm, data, speed, index + 1, width), speed)
        }
    }

    useEffect(() => {
        setTimeout(typeWriter(document.querySelector('.title-extension'), 'Front-end & Back-end', 150), 1000)
    })

    return <section className='home' id='hero'>
        <div className='hero' >

            <div className='info-container' >

                <div className='main'>

                    <div className='name-container'>
                        <h1 className='name'>Nelson Cabrera Cano</h1>
                    </div>

                    <div className='title-container'>
                        <h3 className='title'>Full-stack developer</h3>
                    </div>

                    <div className='title-extension-container'>
                        <h5 className='title-extension' style={ { maxWidth: '0rem' } }>Front-end & Back-end</h5>
                    </div>
                </div>

                <div className='phrase-container'>
                    <p className='phrase'>&lt;p&gt;Learning only broadens the horizon of our ignorance&lt;/p&gt;</p>
                </div>

            </div>


            <figure className='arrow-container'>
                <a href='/#about'><img src='images/home/arrow.svg' alt='Go to about' /></a>
            </figure>

        </div>
    </section>
}