import React, { useState } from 'react'

// Services
import { sendMail } from '../../../services/db.service'

// Styles
import './contactForm.css'

const messages = {
    reset: '',
    email: 'The email have to be valid',
    subject: 'You should especify a subject',
    content: "The content should't be empty",
    server: 'Have been a problem, please retry later',
    sent: "Your message have been sended"
}


export default props => {
    const [form, setForm] = useState({ email: '', subject: '', content: '' })
    const [error, setError] = useState({ email: false, subject: false, content: false })
    const [message, setMessage] = useState({ content: '', error: false })


    // Reset
    const resetForm = () => setForm({ email: '', subject: '', content: '' })

    // Handlers
    const handleInput = e => {
        handleMessage('reset')
        setForm({ ...form, [e.target.id]: e.target.value }, test(e.target.id))
    }
    const handleSubmit = e => {
        e.preventDefault()

        const testResult = test()
        if (testResult === true) {
            sendMail({ ...form, createdAt: new Date() })
                .then(res => handleMessage('sent', resetForm()))
                .catch(err => handleMessage('server'))

        } else handleMessage(testResult)
    }

    const handleMessage = type => {
        switch (type) {
            case 'reset':
            case 'sent': return setMessage({ content: messages[type], error: false })
            case 'email':
            case 'subject':
            case 'content':
            case 'server':
                return setMessage({ content: messages[type], error: true })
            default:
                return setMessage({ content: 'Something bad occurred', error: true })
        }
    }

    // Input test
    const testEmail = () => {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (re.test(form.email.toLowerCase())) setError({ ...error, email: false })
        else setError({ ...error, email: true })
        return !error.email
    }
    const testSubject = () => {
        if (!(form.subject.length === 0)) setError({ ...error, subject: false })
        else setError({ ...error, subject: true })
        return !error.subject
    }
    const testContent = () => {
        if (!(form.content.length === 0)) setError({ ...error, content: false })
        else setError({ ...error, content: true })
        return !error.content
    }
    const testAllInputs = () => testEmail() ? testSubject() ? testContent() ? true : 'content' : 'subject' : 'email'

    const test = input => {
        switch (input) {
            case 'email': return testEmail()
            case 'subject': return testSubject()
            case 'content': return testContent()
            default: return testAllInputs()
        }
    }

    return <div className='contactForm'>
        <div className='formHeaderContainer'>
            <h3>Contact form</h3>
            <img className='closeFormImage' src='./images/other/close.svg' onClick={ props.closeForm } alt='close' />
        </div>

        <form onSubmit={ handleSubmit }>
            <div className='formContainer'>

                <div className='inputContainer inputContainer-input' >
                    <label htmlFor='email'>Email</label>
                    <div className='errorImageContainer'>
                        <input id='email' className={ `${error.email && 'inputError'}` } type='text' placeholder='Email to talk back to you' value={ form.email } onChange={ handleInput } onBlur={ () => test('email') } />
                        { error.email && <img className='errorImage' src='./images/other/cerrar.svg' alt='error' /> }
                    </div>
                </div>

                <div className='inputContainer inputContainer-input'>
                    <label htmlFor='subject' >Subject</label>
                    <div className='errorImageContainer'>
                        <input id='subject' className={ `${error.subject && 'inputError'}` } type='text' placeholder='Why do you want to contact with me? ;D' value={ form.subject } onChange={ handleInput } onBlur={ () => test('subject') } />
                        { error.subject && <img className='errorImage' src='./images/other/cerrar.svg' alt='error' /> }
                    </div>
                </div>

                <div className='inputContainer inputContainer-textarea'>
                    <label htmlFor='content'>Content</label>
                    <div className='errorImageContainer' >
                        <textarea id='content' className={ `${error.content && 'inputError'}` } type='text-area' placeholder='What you want to say :D' value={ form.content } onChange={ handleInput } onBlur={ () => test('content') } />
                        { error.content && <img className='errorImage' src='./images/other/cerrar.svg' alt='error' /> }
                    </div>
                </div>


            </div>
            <p className={ `message ${message.error ? 'msg-error' : 'msg-info'}` }>{ message.content }</p>
            <div className='btn-container'>
                <button type='submit' >Submit</button>
            </div>
        </form>
    </div>
}
